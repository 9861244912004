<template>
  <ul class="menu-inner py-1">
    <!-- Dashboards -->
    <li class="menu-item" :class="{ active: $route.path === '/home' }">
      <router-link to="/home" class="menu-link"> <i class="menu-icon fi fi-rr-dashboard"></i>
        <div data-i18n="Dashboards">Dashboards</div>
      </router-link>
    </li>

    <li class="menu-item" :class="{ active: $route.path === '/register-user-list' }">
      <router-link to="/register-user-list" class="menu-link">
        <i class="menu-icon fi fi-rr-list"></i>
        <div data-i18n="users">Registered Customer</div>
      </router-link>
    </li>
    <li class="menu-item" :class="{ active: $route.path === '/add-new-container' }">
      <router-link to="/add-new-container" class="menu-link">
        <i class="menu-icon fi fi-rr-truck-container"></i>
        <div data-i18n="Port">Add Containers</div>
      </router-link>
    </li>
    <li class="menu-item text-start" :class="{ active: $route.path === '/container-search' }">
      <router-link to="/container-search" class="menu-link">
        <i class="menu-icon fi fi-rr-search"></i>
        <div data-i18n="Port">Search Container</div>
      </router-link>
    </li>

    <li class="menu-item" :class="{ active: $route.path === '/active-containers' }">
      <router-link to="/active-containers" class="menu-link">
        <i class="menu-icon fi fi-rr-list"></i>
        <div data-i18n="Port">Active Containers</div>
      </router-link>
    </li>
    <li class="menu-item" :class="{ active: $route.path === '/container-under-booking' }">
      <router-link to="/container-under-booking" class="menu-link text-start">
        <i class="menu-icon fi fi-rr-list"></i>
        <div data-i18n="Dashboards">Active Bookings</div>
      </router-link>
    </li>
    <li class="menu-item">
      <span class="menu-link text-truncate cursor-pointer" :class="{ active: isMenuActive }" data-bs-toggle="collapse"
        data-bs-target="#collapseExample" aria-expanded="true" aria-controls="collapseExample" @click="toggleCollapse">
        <i class="menu-icon fi fi-rr-recycle"></i>
        <div data-i18n="Re Use Container">Re Use Container</div>
        <i :class="isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'"
          style="font-size: 12px; right: 10px; position: absolute"></i>
      </span>

      <div class="collapse" id="collapseExample" :class="{ show: isMenuActive }">
        <ul>
          <li class="menu-item text-start">
            <router-link to="/reuse-container/add-details" class="menu-link cursor-pointer"
              :class="{ active: isActive('/reuse-container/add-details') }">
              <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
              <div data-i18n="Port">Linking Requests</div>
            </router-link>
          </li>
          <li class="menu-item text-start">
            <router-link to="/reuse-container/delinking-request-list" class="menu-link cursor-pointer"
              :class="{ active: isActive('/reuse-container/delinking-request-list') }">
              <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
              <div data-i18n="Port">Delinking Requests</div>
            </router-link>
          </li>
        </ul>
      </div>
    </li>

    <li class="menu-item text-start" :class="{ active: $route.path === '/payment-pending' }">
      <router-link to="/payment-pending" class="menu-link">
        <i class="menu-icon fi fi-rs-transaction-globe"></i>
        <div data-i18n="Port">Payments Pending</div>
      </router-link>
    </li>



    <li class="menu-item text-start" :class="{ active: $route.path === '/notification-list' }">
      <router-link to="/notification-list" class="menu-link">
        <i class="menu-icon fi fi-rr-bell-notification-social-media"></i>

        <div data-i18n="Port">Notification</div>
      </router-link>
    </li>

    <li class="menu-item text-start" :class="{ active: $route.path === '/history' }">
      <router-link to="/history" class="menu-link">
        <i class="menu-icon fi fi-rr-time-past"></i>

        <div data-i18n="Port">History</div>
      </router-link>
    </li>

    <li class="menu-item">
      <span class="menu-link text-truncate cursor-pointer" :class="{ active: isSettingsMenuActive }"
        data-bs-toggle="collapse" data-bs-target="#collapseExampleMaster" aria-expanded="true"
        aria-controls="collapseExampleMaster" @click="toggleSettingsCollapse">
        <i class="menu-icon fi fi-rr-settings"></i>
        <div data-i18n="Settings">Settings</div>
        <i :class="isSettingsCollapsed ? 'fi fi-rr-angle-down' : 'fi fi-rr-angle-right'"
          style="font-size: 12px; right: 10px; position: absolute"></i>
      </span>

      <div class="collapse" id="collapseExampleMaster" :class="{ show: isSettingsMenuActive }">
        <div style="height: 200px; overflow-y: scroll;">
          <ul>
            <li class="menu-item text-start" v-for="item in settingsMenuItems" :key="item.route">
              <router-link :to="item.route" class="menu-link cursor-pointer"
                :class="{ active: isSettingsActive(item.route) }">
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div :data-i18n="item.label">{{ item.label }}</div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminNavbar",
  data() {
    return {

      isCollapsed: true, // default state for collapse
      isSettingsCollapsed: false,


      settingsMenuItems: [
        { route: "/master/country", label: "Country" },
        { route: "/master/state", label: "State" },
        { route: "/master/district", label: "District" },
        { route: "/master/taluka-list", label: "Talukas" },
        { route: "/master/village-area-list", label: "Village / Area" },
        { route: "/master/state-pincode-ranges", label: "State Pin Code Ranges" },
        { route: "/master/booking-rejection-reasons", label: "Booking Rejection Reason" },
        { route: "/master/admin-rejection-reasons", label: "Admin Rejection Reason" },
        { route: "/master/exporter-rejection-reasons", label: "Exporter Rejection Reason" },
        { route: "/master/shippingline-rejection-reasons", label: "Shippingline Rejection Reason" },
        { route: "/master/shippingline-delink-reasons", label: "Shippingline Delink Reason" },
        { route: "/master/shipping-line-charges", label: "Pricing" },
        { route: "/master/check-out-points", label: "Check Out Points" },
        { route: "/master/port", label: "Port" },
        { route: "/master/axle-list", label: "Truck Axle Weight Category" },
        { route: "/master/commodity-list", label: "Commodity Type in Container" },
        { route: "/master/market-price", label: "Market Price" },
      ],
    };
  },

  computed: {
    isSettingsMenuActive() {
      return this.settingsMenuItems.some((item) => this.isSettingsActive(item.route));
    },
    isMenuActive() {
      return (
        this.isActive('/reuse-container/add-details') ||
        this.isActive('/reuse-container/delinking-request-list')
      );
    },
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },

    toggleSettingsCollapse() {
      this.isSettingsCollapsed = !this.isSettingsCollapsed;
    },
    isSettingsActive(route) {
      return this.$route.path === route;
    },

  },


};
</script>

<style scoped></style>
