<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme "
    :class="['layout-menu', 'menu-vertical', 'menu', 'bg-menu-theme ', { 'is-open': isSidebarOpen }]">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width: 100%" src="/assets/img/logo/BOW.png" alt="" />
      </router-link>
      
      <span class=" ms-auto d-block d-xl-none">
        <span @click="SideBarOpenClose" style="margin-right: -20px;"> 
        <i class="fs-3 fi fi-rr-arrow-circle-left"></i>
      </span>
      </span>


    </div>
    <hr />
    <div class="menu-inner-shadow"></div>
    <AdminNav />
  </aside>
</template>


<script>
import AdminNav from "@/components/common/AdminNav.vue";
import { EventBus } from '@/eventBus';
export default {
  name: "Sidebar",
  data() {
    return {
      isSidebarOpen: false,
      isMobile: window.innerWidth <= 768, 
    };
  },
  components: {
    AdminNav,
  },
  methods: {

    SideBarOpenClose() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
      if (!this.isMobile) {
        this.isSidebarOpen = true; // Keep open on larger screens
      }
    },
  },
  // mounted() {
  //   window.addEventListener("resize", this.handleResize);
  //   this.handleResize(); // Set the correct state on mount
  // },
  // beforeDestroy() {
  //   window.removeEventListener("resize", this.handleResize);
  // },


  mounted() {
    // Listen for the event triggered by Head.vue
    EventBus.on("callSideMethod", this.SideBarOpenClose);
  },
  beforeUnmount() {
    // Clean up the listener
    EventBus.off("callSideMethod", this.SideBarOpenClose);
  },
};
</script>



<style scoped>
/* Sidebar hidden by default on mobile */
.layout-menu {
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
}

/* When the sidebar is open */
.layout-menu.is-open {
  transform: translateX(0);
}

/* Always show sidebar on larger screens */
@media (min-width: 769px) {
  .layout-menu {
    transform: translateX(0);
  }
}
</style>
